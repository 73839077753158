/* SignUp.css */
.sign-up-center-container {
  display: flex ;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.sign-up-container {
  width: 400px;
  max-width: 100%;;
  margin: 0 auto;
  padding: 2em;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.sign-up-container h2 {
  margin-bottom: 20px;
}

.sign-up-form {
  width: calc(100% - 2em);
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
}

.sign-up-form-group {
  width: calc(100% - 2em);
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  text-align: left;
}

.sign-up-form-group label {
  display: block;
  margin-bottom: 5px;
  align-self: flex-start;
}

.sign-up-form-group input {
  width: calc(100% - 2em);
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.error {
  color: red;
  margin-top: 10px;
}

.sign-up-form-group button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 1em;
}

.sign-up-form-group button:hover {
  background-color: #0056b3;
}