.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px 20px;
  display: flex;
  flex-flow: column nowrap;
}

.prompt-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title {
  font-size: 2em;
  margin: 0 0 0.25em;
  overflow: auto;
}

.subtitle {
  font-size: 1em;
  margin: 0;
  color: #777;
}

.small-subtitle {
  font-size: 1em;
  margin: 0;
  color: #aaa; /* Even lighter color for the small subtitle */
}

.prompt-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}

.prompt-item {
  background: #e5e5cc; /* #f0f0f0; */
  padding: 10px;
  border-radius: 5px;
  text-align: left;
}

.prompt-index {
  font-weight: bold;
  margin-right: 5px;
}

.prompt-text {
  font-size: 1em;
}

.scroll-message {
  position: absolute;
  bottom: 16px;
  padding: 10px;
  text-align: center;
  font-size: 0.9em;
  color: #888;
}

.all-prompts-header {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center; /* Center text */
  width: 100%; /* Ensure it takes full width */
  margin: 2em 0 0; /* Add some margin for spacing */
}

.start-journaling-container {
  margin-top: 3em;;
}