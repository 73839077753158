/* Account.css */

.account-center-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.account-container {
  max-width: calc(100% - 2em);
  width: 800px;
  margin: 96px auto;
  padding: 1em;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.account-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.entries-table {
  width: 100%;
  border-collapse: collapse;
}

.entries-table th, .entries-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.entries-table th {
  background-color: #f2f2f2;
}

.entries-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.entries-table tr:hover {
  background-color: #f1f1f1;
}

.no-entries-container {
  text-align: center;
  margin-top: 1em;
}

.no-entries-container a {
  color: #777;
}