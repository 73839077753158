/* CreateEntry.css */
.create-entry-container {
  max-width: calc(100% - 2em);
  width: 800px;
  margin: 96px auto 0;
  padding: 1em;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.create-entry-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.create-entry-form-group {
  margin-bottom: 15px;
}

.create-entry-form-group label {
  display: block;
  margin-bottom: 5px;
}

.create-entry-form-group p {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
}

.create-entry-form-group textarea {
  width: calc(100% - 2em);
  height: 150px;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}

.error {
  color: red;
  margin-top: 10px;
}

.success {
  color: green;
  margin-top: 10px;
}

.create-entry-form-group button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.create-entry-form-group button:hover {
  background-color: #0056b3;
}

.create-entry-form-group button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.caption {
  font-size: 0.8em;
  color: #777;
  margin-top: 10px;
}
