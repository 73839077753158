.app-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2em);
  padding: 10px 0;
  text-align: center;
  z-index: 1000;
  display: flex;
  flex-flow: row nowrap;
  padding: 1em;
  background-color: #e5e5cc;
  align-items: center;
}

.app-bar-title {
  margin: 0;
  font-size: 1.5em;
  margin-right: 1em;
}

.hamburger-menu {
  font-size: 1.5em;
  background: none;
  border: none;
  cursor: pointer;
  display: none;
  color: #777;
}

.nav-bar {
  display: absolute;
  align-items: center;
  display: flex;
  gap: 20px;
  top: 1em;
  flex-flow: row nowrap;
}

.nav-bar.open {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-link {
  color: #777;
  text-decoration: none;
  font-size: 1.2em;
}

.nav-link-bold {
  font-weight: bold;
}

.nav-link:hover {
  text-decoration: underline;
}

.footer {
  font-size: 0.8em; /* Small font size */
  text-align: center; /* Center the text */
  margin-bottom: 20px; /* Add some margin at the top */
  color: #555; /* Darker color for the text */
  margin-top: 8px;
}

.app-bar-right-buttons {
  position: relative;
  display: flex;
  gap: 1em;
  padding-left: 1em;
  border-left: 1px solid #ccc;
}

@media (max-width: 950px) {
  .hamburger-menu {
    display: block;
  }

  .nav-bar {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 999;
    background-color: #e5e5cc;
    padding: 1em 0;
  }

  .nav-bar.open {
    display: flex;
  }

  .nav-link {
    width: 100%;
    text-align: center;
  }

  .app-bar-right-buttons {
    border-top: 1px solid #ccc;
    position: relative;
    flex-direction: column;
    right: 0;
    width: 100%;
    padding-top: 1em;
  }
}

.app-bar-title-link {
  text-decoration: none;
  color: #000;
}
