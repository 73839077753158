/* LandingPage.css */
.landing-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.landing-page-container h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.topics-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.topic-link {
  display: block;
  padding: 20px 40px;
  text-decoration: none;
  font-size: 1.5em;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  color: #777;
}

.topic-link:hover {
  text-decoration: underline;
}

.subtitle {
  font-size: 1.5em;
  margin: 0;
  color: #777;
}

.divider {
  width: 80%;
  margin: 20px auto;
  border: 0;
  border-top: 1px solid #ccc;
}
